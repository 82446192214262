import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import DocumentationOverview from '../../components/help/DocumentationOverview'

const DocsPage = ({ data }) => {
  const docs = data.docs.nodes

  const allDirectories = data.folders.nodes.map(node => node.relativeDirectory)

  const folderCountMap = {}

  allDirectories.forEach(dir => {
    const parts = dir.split('/')
    if (parts.length >= 2) {
      const secondLevelFolder = parts[1]

      if (folderCountMap[secondLevelFolder]) {
        folderCountMap[secondLevelFolder] += 1
      } else {
        folderCountMap[secondLevelFolder] = 1
      }
    }
  })

  const folderList = Object.keys(folderCountMap).map(folder => ({
    name: folder,
    count: folderCountMap[folder]
  }))

  return (
    <Layout
      title="Atlas Documentation - Learn how to use Atlas"
      meta={{
        description:
          'Start with the basics, or explore advanced features to become an Atlas expert. Learn how to use Atlas to create beautiful maps and visualizations.',
        title: 'Atlas Documentation - Learn how to use Atlas',
        type: 'website'
      }}
    >
      <main>
        <DocumentationOverview folderList={folderList} docs={docs} />
      </main>
    </Layout>
  )
}

export default DocsPage

export const pageQuery = graphql`
  query {
    docs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/docs/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          date(formatString: "MMMM D, YYYY")
        }
        fields {
          slug
        }
        parent {
          ... on File {
            relativeDirectory
          }
        }
      }
    }
    folders: allFile(
      filter: { absolutePath: { regex: "/docs/" }, extension: { eq: "md" } }
    ) {
      nodes {
        relativeDirectory
      }
    }
  }
`
